import { BlocksContext } from "./context/blocksContext"
import { useBlocks } from "./hooks/blocks.hook"


import { Loader } from './components/loader';
import "./css/style.css";
import "./css/slick.css";
import "./css/swiper.css";

function App() {
  const { blocks, setBlocks, menuClick, setMenuClick } = useBlocks()
  return (
    <section className="container_main">
      <BlocksContext.Provider value={{ blocks, setBlocks, menuClick, setMenuClick }}>
        <Loader />
      </BlocksContext.Provider>
    </section>
  );
}

export default App;
